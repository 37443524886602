import {
  Grid,
  Box,
  TextField,
  Button,
  Typography,
  CircularProgress,
  Alert,
} from "@mui/material";
import PropTypes from "prop-types";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import moment from "moment";
import axios from "axios";
import { getApiUrl } from "../../utils/isDev";

import * as React from "react";

import Dialog from "@mui/material/Dialog";

import { _mintDune, getUtxosWithOutDunes } from "../../utils/mint";
import { generateWallet } from "../../utils/WalletGen";
import { useFetchTokens } from "../../hooks/useFetchTokens.hook";
import { fetchAllUnspentOutputs } from "../../utils/balance";
import { set } from "bitcore-lib-doge/lib/hdkeycache";

const feePerMint = 5.26;

//node dunes.js mintDune <id> <amount> <to>
const MintComponent = ({ id, setIsLoading, setError, isLoading, error }) => {
  const [wallet, setWallet] = React.useState({ address: null, key: null });
  const [txId, setTxId] = React.useState([]);

  const [data, setData] = React.useState({
    id: id,
    amount: "1",
    to: "",
    number: "1",
    address: "",
    key: "",
  });

  // useEffect to generate and set wallet data once on component mount
  React.useEffect(() => {
    const newWallet = generateWallet();
    setWallet(newWallet);
    // Update state with generated wallet data
    setData((currentData) => ({
      ...currentData,
      address: newWallet.address,
      key: newWallet.key,
    }));
  }, []);

  const handleChange = (prop) => (event) => {
    setData({ ...data, [prop]: event.target.value });
  };

  const handleMint = async () => {
    try {
      setIsLoading(true);
      console.log("Minting Dune...");
      let utxos = await fetchAllUnspentOutputs(data.address);
      if (utxos.length < 1) {
        throw new Error(
          "not enough funds found!, Please send the funds to the address provided. If you have sent the funds, please wait for the transaction to be confirmed and try again."
        );
      }
      let balance = utxos.reduce((acc, curr) => acc + curr.satoshis, 0);
      if (balance == 0 || balance < feePerMint * data.number * 1e8)
        throw new Error(
          "not enough funds found!, Please send the funds to the address provided. If you have sent the funds, please wait for the transaction to be confirmed and try again."
        );
      if (data.number == 1) {
        const utxosWithoutDunes = await getUtxosWithOutDunes(utxos);
        if (utxosWithoutDunes.length < data.number) {
          throw new Error(
            "not enough funds found!, Please send the funds to the address provided. If you have sent the funds, please wait for the transaction to be confirmed and try again."
          );
        }
        console.log(utxosWithoutDunes, "utxosWithoutDunes");
        const resp = await axios.post(`${getApiUrl()}/mint`, {
          id: data.id,
          amount: data.amount,
          receiver: data.to,
          address: data.address,
          key: data.key,
          utxos: utxos,
          utxosWithoutDunes: utxosWithoutDunes,
          balance: balance,
        });
        // alert("Minted successfully");
        setTxId((prevState) => [...prevState, resp.data]);
      } else if (data.number > 1) {
        // if (utxos.length < data.number) {
        const response = await axios.post(getApiUrl() + "/split", {
          utxos: utxos,
          key: data.key,
          splits: data.number,
          address: data.address,
        });
        // }
        utxos = [];
        let utxosWithoutDunes = [];
        while (
          utxos.length < data.number &&
          utxosWithoutDunes.length < data.number
        ) {
          console.log("waiting for utxos");
          utxos = await fetchAllUnspentOutputs(data.address);

          //sort utxos by satoshis from lowest to highest
          utxos = utxos.filter((utxo) => utxo.satoshis >= feePerMint * 1e8);
          if (utxos.length > 0) {
            console.log("utxos", utxos);
            utxosWithoutDunes = await getUtxosWithOutDunes(utxos);
            console.log("utxosWithoutDunes", utxosWithoutDunes);
          }
          await new Promise((resolve) => setTimeout(resolve, 10000));
        }
        for (let i = 0; i < utxosWithoutDunes.length; i++) {
          const current = utxosWithoutDunes[i];
          const resp = await axios.post(`${getApiUrl()}/mint`, {
            id: data.id,
            amount: data.amount,
            receiver: data.to,
            address: data.address,
            key: data.key,
            utxos: utxos,
            utxosWithoutDunes: [current],
            balance: current.satoshis,
          });
          // alert("Minted successfully");
          setTxId((prevState) => [...prevState, resp.data]);
        }
      }
    } catch (error) {
      console.log(error);
      setError(error.toString());
    } finally {
      setIsLoading(false);
    }
  };

  if (txId.length > 0) {
    return (
      <Box
        sx={{
          p: 3,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {isLoading && (
          <Typography>
            Please wait, we are minting your Dunes. This may take a while...
          </Typography>
        )}
        <Alert severity="success">
          {txId.length}/{data.number} Transaction broadcasted successfully
        </Alert>
        {txId.length < data.number && error && (
          <Alert severity="error">
            {txId.length} out of {data.number} Transactions broadcasted
            successfully, {error.toString()}
          </Alert>
        )}

        {txId.map((tx, index) => {
          return (
            <Button
              key={index}
              variant="outlined"
              onClick={() => {
                window.open(
                  `https://live.blockcypher.com/doge/tx/${tx}`,
                  "_blank"
                );
              }}
            >
              View Transaction {index + 1} {tx}
            </Button>
          );
        })}
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Dune Id"
            variant="outlined"
            sx={{ width: "100%" }}
            // onChange={handleChange("id")}
            value={data.id}
            aria-readonly
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Amount"
            variant="outlined"
            sx={{ width: "100%" }}
            value={data.amount}
            onChange={handleChange("amount")}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="To"
            variant="outlined"
            value={data.to}
            sx={{ width: "100%" }}
            onChange={handleChange("to")}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="number of mints"
            variant="outlined"
            sx={{ width: "100%" }}
            value={data.number}
            onChange={handleChange("number")}
          />
        </Grid>
        <Grid item xs={12}>
          {/* <Typography>Address: {data.address.toString()}</Typography>
          <Typography>key: {data.key.toString()}</Typography> */}
          <Typography>
            Please send{" "}
            {feePerMint * parseInt(data.number) +
              (parseInt(data.number) > 1
                ? 0.5 * parseInt(data.number)
                : 0)}{" "}
            doge to the funding following wallet. Save private key to recover
            any funds.
          </Typography>
          <Alert severity="info">
            This platform charges a small fee for each mint.{" "}
          </Alert>
        </Grid>
        {data.address && data.key && (
          <>
            {" "}
            <Grid item xs={12}>
              <TextField
                label="Address"
                variant="outlined"
                sx={{ width: "100%" }}
                value={data.address.toString()}
                aria-readonly
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Private Key"
                variant="outlined"
                sx={{ width: "100%" }}
                value={data.key.toString()}
                aria-readonly
              />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          {isLoading ? (
            <Button variant="contained" sx={{ width: "100%" }} disabled>
              Loading...
            </Button>
          ) : (
            <Button
              variant="contained"
              sx={{ width: "100%" }}
              onClick={handleMint}
              disabled={
                !data.id ||
                !data.amount ||
                !data.to ||
                !data.address ||
                !data.key ||
                !data.number ||
                isLoading
              }
            >
              Mint
            </Button>
          )}
        </Grid>
        <Grid item xs={12}>
          {error && !isLoading && (
            <Alert severity="error">{error.toString()}</Alert>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

MintComponent.propTypes = {
  id: PropTypes.string.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.any,
};

const TokenTable = ({ value, index }) => {
  const [seleted, setSelected] = React.useState("");

  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState(null);

  const handleClickOpen = (e) => {
    setSelected(e);
  };

  const handleClose = () => {
    setSelected("");
    setError(null);
    setIsLoading(false);
  };

  const { tokens, loading, hasMore, loadMore } = useFetchTokens();
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <>
          {" "}
          <Typography variant="h6" gutterBottom component="div">
            Hover and Click a Dune Token row to Mint
          </Typography>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {/* Replace with your table headers */}
                  <TableCell>number</TableCell>
                  <TableCell align="right" sx={{ border: 1 }}>
                    Symbol
                  </TableCell>
                  <TableCell align="right" sx={{ border: 1 }}>
                    name
                  </TableCell>
                  <TableCell align="right" sx={{ border: 1 }}>
                    Limit
                  </TableCell>
                  <TableCell align="right" sx={{ border: 1 }}>
                    Supply
                  </TableCell>
                  <TableCell align="right" sx={{ border: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography
                          sx={{ width: "100%" }}
                          textAlign={"center"}
                          fontWeight={700}
                        >
                          Start
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography>Block Height</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography>TimeStamp (UTC)</Typography>
                      </Grid>
                    </Grid>
                  </TableCell>

                  <TableCell align="right" sx={{ border: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography
                          sx={{ width: "100%" }}
                          textAlign={"center"}
                          fontWeight={700}
                        >
                          End
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography>Block Height</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography>Est. TimeStamp (UTC)</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography>Time Left</Typography>
                      </Grid>
                    </Grid>
                  </TableCell>
                  {/* ... other headers */}
                </TableRow>
              </TableHead>
              <TableBody>
                {[...new Set(tokens)]
                  .sort((a, b) => a["number"] - b["number"])
                  .map((row, index) => (
                    <TableRow
                      key={row.name + index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },

                        "&:hover": {
                          backgroundColor: "#f5f5f5",
                          cursor: "pointer",
                        },
                      }}
                      onClick={() => handleClickOpen(row.id)}
                    >
                      <TableCell component="th" scope="row">
                        {row.number}
                      </TableCell>
                      <TableCell align="right" sx={{ border: 1 }}>
                        {row.symbol}
                      </TableCell>
                      <TableCell align="right" sx={{ border: 1 }}>
                        {row.name}
                      </TableCell>
                      <TableCell align="right" sx={{ border: 1 }}>
                        {row.limit}
                      </TableCell>
                      <TableCell align="right" sx={{ border: 1 }}>
                        {row.supply}
                      </TableCell>
                      <TableCell align="right" sx={{ border: 1 }}>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Typography>
                              {row["etching block height"]}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography>
                              {moment(row.timestamp).format(
                                "YYYY-MM-DD HH:mm a"
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell align="right" sx={{ border: 1 }}>
                        <Grid container spacing={2}>
                          <Grid item xs={4}>
                            <Typography>{row["end"]}</Typography>
                          </Grid>
                          <Grid item xs={4}>
                            {row["end"] && row["etching block height"] && (
                              <Typography>
                                {moment(row.timestamp)
                                  .add(
                                    row["end"] - row["etching block height"],
                                    "minutes"
                                  )
                                  .format("YYYY-MM-DD HH:mm a")}
                              </Typography>
                            )}
                          </Grid>
                          <Grid item xs={4}>
                            {row["end"] && row["etching block height"] && (
                              <Typography
                                sx={{
                                  backgroundColor:
                                    moment(new Date()) <
                                    moment(row.timestamp).add(
                                      row["end"] - row["etching block height"],
                                      "minutes"
                                    )
                                      ? "rgba(0, 255, 0, 0.5)"
                                      : "rgba(255, 0, 0, 0.5)",
                                }}
                              >
                                {moment(row.timestamp)
                                  .add(
                                    row["end"] - row["etching block height"],
                                    "minutes"
                                  )
                                  .fromNow()}
                              </Typography>
                            )}
                          </Grid>
                        </Grid>
                      </TableCell>
                      {/* ... other cells */}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Dialog
            open={seleted !== ""}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <MintComponent
              setError={setError}
              id={seleted}
              setIsLoading={setIsLoading}
              isLoading={isLoading}
              error={error}
            />
          </Dialog>
        </>
      )}
    </div>
  );
};

TokenTable.propTypes = {
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default TokenTable;
