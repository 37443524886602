import React from "react";
import {
  Alert,
  AppBar,
  Box,
  Button,
  Container,
  Toolbar,
  Typography,
} from "@mui/material";
import OperationsComponent from "./compents/Operations/Operations.component";

function App() {
  return (
    <Box>
      <Container
        maxWidth={{
          xs: "100%",
          sm: "100%",
          md: "100%",
          lg: "100%",
          xl: "100%",
        }}
      >
        <AppBar
          position="static"
          sx={{
            backgroundColor: "black",
          }}
        >
          <Toolbar>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
            >
              dunesondoge.xyz
            </Typography>
            <Box sx={{ display: { xs: "none", sm: "block" } }}>
              <Button
                color="inherit"
                onClick={() => {
                  window.open(
                    "https://twitter.com/verydogelabs/status/1764775278251856374?t=isQeNH18QLHcQ2u6-ljBFA",
                    "_blank"
                  );
                }}
              >
                Great X (Twitter) thread by @verydogelabs
              </Button>

              <Button
                color="inherit"
                onClick={() => {
                  window.open("https://ord.dunesprotocol.com/dunes", "_blank");
                }}
              >
                Dunes List
              </Button>
            </Box>
          </Toolbar>
        </AppBar>
        <Typography variant="h3" style={{ marginTop: "20px" }}>
          Dunes on Doge (Dune Protocol)
        </Typography>

        <Typography
          style={{
            // fontWeight: "bold",
            fontSize: "1.2em",
            color: "black",
          }}
        >
          <span
            style={{
              fontWeight: "bold",
              fontSize: "1.2em",
              color: "black",
            }}
          >
            Info:
          </span>{" "}
          This is a simple UI to interact with the dune protocol on the dogecoin
          network. Please use it at your own risk!!, No updates are planned for
          this UI yet, besides transfers(TBD), again DYOR before using it.
        </Typography>

        <OperationsComponent />
      </Container>
    </Box>
  );
}

export default App;
