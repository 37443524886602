import { Edict, constructScript, parseDuneId } from "./helpers";
import dogecore from "bitcore-lib-doge";
import axios from "axios";
import bitcoinjs from "bitcoinjs-lib";
import rateLimit from "axios-rate-limit";

//import cheerio
import cheerio from "cheerio";
import WalletUtxoHandler from "./walletUtxo";
const { PrivateKey, Address, Transaction, Script, Opcode } = dogecore;

async function getDunesForUtxo(outputHash) {
  const ordApi = axios.create({
    baseURL: "https://ord.dunesprotocol.com/",
    timeout: 100_000,
  });

  try {
    const response = await ordApi.get(`/output/${outputHash}`);
    const html = response.data;
    const $ = cheerio.load(html);

    const dunes = [];
    $("table tr").each((index, element) => {
      if (index === 0) return; // Skip the header row

      const cells = $(element).find("td");
      if (cells.length === 2) {
        const dune = $(cells[0]).text().trim();
        const amountString = $(cells[1]).text().trim().split(" ")[0];
        const amount = amountString;
        dunes.push({ dune, amount, utxo: outputHash });
      }
    });

    return dunes;
  } catch (error) {
    console.error("Error fetching or parsing data:", error);
    throw error;
  }
}

export const getUtxosWithOutDunes = async (utxos) => {
  // let wallet = JSON.parse(fs.readFileSync(WALLET_PATH));

  const safeUtxos = [];
  // for (const [index, utxo] of wallet.utxos.entries()) {
  //   console.log(`Processing utxo number ${index} of ${wallet.utxos.length}`);
  //   const dunesOnUtxo = await getDunesForUtxo(`${utxo.txid}:${utxo.vout}`);
  //   if (dunesOnUtxo.length === 0) {
  //     safeUtxos.push(utxo);
  //   }
  // }

  for (let index = 0; index < utxos.length; index++) {
    const utxo = utxos[index];
    const dunesOnUtxo = await getDunesForUtxo(
      `${utxo.txid}:${utxo.vout}`
    );
    if (dunesOnUtxo.length === 0) {
      safeUtxos.push(utxo);
    }
  }

  return safeUtxos;
};
//mint one dune
export const _mintDune = async ({ id, amount, receiver, address, key }) => {
  try {
    let isMassMint = false;
    console.log("Minting Dune...");
    console.log(id, amount, receiver);

    // Parse given id string to dune id
    const duneId = parseDuneId(id, true);

    // mint dune with encoded id, amount on output 1
    const edicts = [new Edict(duneId, amount, 1)];
    console.log(edicts);

    // Create script for given dune statements
    const script = constructScript(null, undefined, null, edicts);

    console.log(script);
    const walletUtxo = new WalletUtxoHandler();
    const { utxos, balance } = await walletUtxo.walletUtxo(address, 5);
    console.log(utxos, balance);

    // // getting the wallet balance
    // let wallet = JSON.parse(fs.readFileSync(WALLET_PATH));
    // let balance = wallet.utxos.reduce((acc, curr) => acc + curr.satoshis, 0);
    if (balance == 0) throw new Error("no funds");

    // // creating new tx
    let tx = new Transaction();

    // // output carries the protocol message
    tx.addOutput(
      new dogecore.Transaction.Output({ script: script, satoshis: 0 })
    );

    // // add receiver output holding dune amount
    tx.to(receiver, 100_000);

    tx.change(address);
    delete tx._fee;

    let utxosWithoutDunes = await getUtxosWithOutDunes(utxos);
    console.log(utxosWithoutDunes);

    // we sort the largest utxos first
    const sortedUtxos = utxosWithoutDunes.slice().sort((a, b) => {
      return b.value - a.value;
    });

    // we filter for utxos that are larger than 1 DOGE
    const largeUtxos = sortedUtxos.filter((utxo) => {
      return utxo.value >= 1_000_000;
    });

    for (const utxo of largeUtxos) {
      if (
        tx.inputs.length &&
        tx.outputs.length &&
        tx.inputAmount >= tx.outputAmount + tx.getFee() &&
        tx.inputAmount >= 1_500_000
      ) {
        break;
      }

      if (isMassMint && tx.inputAmount >= tx.outputAmount) {
        break;
      }

      if (!isMassMint) {
        delete tx._fee;
      }

      // tx.from(utxo);
      if (!utxo.satoshis) {
        tx.from({
          txId: utxo.tx_hash,
          outputIndex: utxo.tx_output_n,
          script: utxo.script,
          satoshis: utxo.value,
        });
      } else {
        tx.from(utxo);
      }
      tx.change(address);

      if (isMassMint) {
        delete tx._fee;
      }
    }

    console.log("tx", tx.toString());

    tx.sign(key);

    if (isMassMint) {
      delete tx._fee;
    }

    if (tx.inputAmount < tx.outputAmount + tx.getFee()) {
      throw new Error("not enough (secure) funds");
    }

    console.log("tx", tx);
  } catch (error) {
    console.error("Error minting dune:", error);
  }
  // // send tx
};

