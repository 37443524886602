import { useState, useEffect, SetStateAction } from "react";
import { getApiUrl } from "../utils/isDev";

export function useFetchTokens(limit = 10) {
  const [tokens, setTokens] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
    async function fetchTokens() {
      try {
        const response = await fetch(`${getApiUrl()}/dunes`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const newTokens = await response.json();
        setTokens(newTokens);
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    }

    fetchTokens();
  }, []);

  const loadMore = () => {
    setPage((prevPage) => prevPage + 1);
  };

  return { tokens, loading, error, hasMore, loadMore };
}
