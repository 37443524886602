import axios from "axios";

import axiosRetry from "axios-retry";

const unspentApi = axios.create({
  baseURL: "https://unspent.dogeord.io/api/v1/address/unspent/",
  timeout: 100_000,
});
const axiosRetryOptions = {
  retries: 10,
  retryDelay: axiosRetry.exponentialDelay,
};
axiosRetry(unspentApi, axiosRetryOptions);

export async function fetchAllUnspentOutputs(walletAddress) {
  let page = 1; // Start from the first page
  let allUnspentOutputs = []; // Array to hold all unspent outputs
  let hasMoreData = true; // Flag to keep the loop running until no more data is available

  while (hasMoreData) {
    try {
      console.log(`Fetching unspent outputs for page ${page}...`);
      // Fetch data from the API for the given page
      const response = await unspentApi.get(`/${walletAddress}/${page}`);
      const outputs = response.data.unspent_outputs;

      // Check if the response contains any unspent outputs
      if (outputs && outputs.length > 0) {
        // Map and concatenate the current page's data to the total
        const mappedOutputs = outputs.map((output) => ({
          txid: output.tx_hash,
          vout: output.tx_output_n,
          script: output.script,
          satoshis: Number(output.value),
        }));

        allUnspentOutputs = allUnspentOutputs.concat(mappedOutputs);
        page++; // Increment the page number to fetch the next page
      } else {
        hasMoreData = false; // No more data to fetch, exit the loop
      }
    } catch (error) {
      console.error("Error fetching unspent outputs:", error);
      break; // Exit the loop in case of an error
    }
  }

  return allUnspentOutputs; // Return the collected unspent outputs
}

async function getDunesForUtxos(hashes) {
  const ordApi = axios.create({
    baseURL: "https://ord.dunesprotocol.com/",
    timeout: 100_000,
  });

  try {
    const response = await ordApi.get(`/outputs/${hashes.join(",")}`);
    const parsed = response.data;

    const dunes = [];

    parsed.forEach((output) => {
      if (output.dunes.length > 0) {
        dunes.push({ dunes: output.dunes, utxo: output.txid });
      }
    }, []);

    return dunes;
  } catch (error) {
    console.error("Error fetching or parsing data:", error);
    throw error;
  }
}

const getBalance = async (address, dune_nam = undefined) => {
  const utxos = await fetchAllUnspentOutputs(address);
  let balances = {};
  const utxoHashes = utxos.map((utxo) => `${utxo.txid}:${utxo.vout}`);
  const chunkSize = 50; // Size of each chunk

  // Function to chunk the utxoHashes array
  const chunkedUtxoHashes = [];
  for (let i = 0; i < utxoHashes.length; i += chunkSize) {
    chunkedUtxoHashes.push(utxoHashes.slice(i, i + chunkSize));
  }

  // Process each chunk
  for (const chunk of chunkedUtxoHashes) {
    const allDunes = await getDunesForUtxos(chunk);

    for (const dunesInfo of allDunes) {
      for (const singleDunesInfo of dunesInfo.dunes) {
        const [name, { amount }] = singleDunesInfo;

        if (!balances[name]) {
          balances[name] = BigInt(0);
        }
        balances[name] += BigInt(amount);
      }
    }
  }

  // Output the total balance
  console.table(balances);

  // if (dune_nam) {
  //   return balances[dune_nam];
  // }
  return balances;
};

export default getBalance;
