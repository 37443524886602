import axios from "axios";
import rateLimit from "axios-rate-limit";

// Adjusted rate limit for http: 6 requests per minute
const http = rateLimit(axios.create(), {
  maxRequests: 6,
  perMilliseconds: 60 * 1000, // 60 seconds in milliseconds
});

// Adjusted rate limit for http1: 2 requests per minute
const http1 = rateLimit(axios.create(), {
  maxRequests: 2,
  perMilliseconds: 60 * 1000, // 60 seconds in milliseconds
});

class WalletUtxoHandler {
  // Method to pause execution for a specified time (in milliseconds)
  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  // Fetch UTXOs using the Blockcypher API
  async walletUtxo2(
    address,
    hasData) {
    console.log("syncing utxos with dogechain.info api");
    let response = await http1.get(
      `https://api.blockcypher.com/v1/doge/main/addrs/${address}?unspentOnly=true&includeScript=true`
    );
    console.log(response.data);
    const txrefs = response.data.txrefs;
    const unconfirmed_txrefs = response.data.unconfirmed_txrefs;

    if (!txrefs && !unconfirmed_txrefs && hasData) {
      await this.sleep(20000);
      return await this.walletUtxo1(address, 0);
    } else {
      let balance = response.data.balance;
      let utxos = [];
      if (txrefs) {
        utxos = [...txrefs];
      }
      if (unconfirmed_txrefs) {
        utxos = [...utxos, ...unconfirmed_txrefs];
      }

      console.log(`${address} balance`, balance);
      return { utxos, balance };
    }
  }

  // Fetch UTXOs using the Blockchair API
  async walletUtxo1(
    address,
    index
  ){
    let links = [
      `https://api.blockchair.com/dogecoin/mempool/outputs?q=is_spent(false),recipient(${address})&limit=20`,
      `https://api.blockchair.com/dogecoin/outputs?q=is_spent(false),recipient(${address})&limit=20`,
    ];
    let response = await http.get(links[index]);
    if (!response.data.data || response.data.data.length === 0) {
      response = await http.get(links[index === 0 ? 1 : 0]);
      if (!response.data.data || response.data.data.length === 0) {
        await this.sleep(20000);
        return await this.walletUtxo2(address);
      }
    } else {
      const utxos = response.data.data.map(
        (utxo) => {
          return {
            tx_hash: utxo.transaction_hash,
            tx_output_n: utxo.index,
            script: utxo.script_hex,
            address: utxo.recipient,
            value: utxo.value,
          };
        }
      );
      const balance = utxos.reduce(
        (acc, utxo) => acc + utxo.value,
        0
      );
      return { utxos, balance };
    }
  }
  // Main method to fetch UTXOs, with retry logic
  async walletUtxo(
    address,
    maxRetries
  ) {
    let retryCount = 0;
    let useFirstAPI = true;

    while (retryCount < maxRetries) {
      try {
        const result = useFirstAPI
          ? await this.walletUtxo1(address, Math.round(Math.random()))
          : await this.walletUtxo2(address);

        if (result) return result;
      } catch (error) {
        // Add refined error handling here
        console.error(
          `Error in ${useFirstAPI ? "walletUtxo1" : "walletUtxo2"}:`,
          error
        );
      }

      useFirstAPI = !useFirstAPI;
      retryCount++;
      await this.sleep(20 * 1000); // Adjust the sleep duration as needed
    }

    throw new Error(
      `Failed to retrieve UTXOs for address ${address} after ${maxRetries} attempts.`
    );
  }
}

export default WalletUtxoHandler;
