
import dogecoin from 'bitcore-lib-doge';

export function generateWallet() {
  const node = new dogecoin.PrivateKey();

  console.log(`

Wallet generated: 

- Address : ${node.toAddress()},
- Key : ${node.toWIF()},

`);

  return {
    address: node.toAddress(),
    key: node.toWIF(),
  };
}