import {
  Grid,
  Box,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Divider,
  Alert,
} from "@mui/material";
import PropTypes from "prop-types";

import * as React from "react";
import { _mintDune } from "../../utils/mint";
import getBalance from "../../utils/balance";

//node dunes.js mintDune <id> <amount> <to>
const WalletCheckerComponent = ({ value, index }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [data, setData] = React.useState({
    id: "JACKSONPALMER",
    wallet: "",
    balance: {},
  });

  const handleChange = (prop) => (event) => {
    setData({ ...data, [prop]: event.target.value });
  };

  const handleMint = async () => {
    try {
      setIsLoading(true);
      console.log("getting balance of Dune...");
      const balance = await getBalance(data.wallet);
      console.log("balance", balance);
      setData({ ...data, balance });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="wallet"
                variant="outlined"
                sx={{ width: "100%" }}
                onChange={handleChange("wallet")}
                value={data.wallet}
              />
            </Grid>

            <Grid item xs={12}>
              {isLoading ? (
                <Button variant="contained" sx={{ width: "100%" }} disabled>
                  Loading...
                </Button>
              ) : (
                <Button
                  variant="contained"
                  sx={{ width: "100%" }}
                  onClick={handleMint}
                >
                  Check Balance
                </Button>
              )}
              {error && !isLoading && (
                <Alert severity="error">{error.message}</Alert>
              )}
            </Grid>
          </Grid>

          <Divider sx={{ my: 2 }} />

          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Dune</TableCell>
                  <TableCell align="right">Balance</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(data.balance).map((key) => (
                  <TableRow key={key}>
                    <TableCell component="th" scope="row">
                      {key}
                    </TableCell>
                    <TableCell align="right">{data.balance[key].toString()}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </div>
  );
};

WalletCheckerComponent.propTypes = {
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default WalletCheckerComponent;
