import dogecore from "bitcore-lib-doge";
const { PrivateKey, Address, Transaction, Script, Opcode } = dogecore;
const PROTOCOL_IDENTIFIER = "D";



const IDENTIFIER = stringToCharCodes(PROTOCOL_IDENTIFIER);
const MAX_SCRIPT_ELEMENT_SIZE = 520;


function stringToCharCodes(inputString) {
  const charCodes = [];
  for (let i = 0; i < inputString.length; i++) {
    charCodes.push(inputString.charCodeAt(i));
  }
  return charCodes;
}
export const parseDuneId = (id, claim = false) => {
  // Check if Dune ID is in the expected format
  const regex = /^\d+\/\d+$/;
  if (!regex.test(id))
    console.log(`Dune ID ${id} is not in the expected format e.g. 1234/1`);

  // Parse the id string to get height and index
  const [heightStr, indexStr] = id.split("/");
  const height = parseInt(heightStr, 10);
  const index = parseInt(indexStr, 10);

  // Set the bits in the id using bitwise OR
  let duneId = (BigInt(height) << BigInt(16)) | BigInt(index);

  // For minting set CLAIM_BIT
  if (claim) {
    const CLAIM_BIT = BigInt(1) << BigInt(48);
    duneId |= CLAIM_BIT;
  }

  return duneId;
};

class PushBytes {
  constructor(bytes) {
    this.bytes = Buffer.from(bytes);
  }

  static fromSliceUnchecked(bytes) {
    return new PushBytes(bytes);
  }

  static fromMutSliceUnchecked(bytes) {
    return new PushBytes(bytes);
  }

  static empty() {
    return new PushBytes([]);
  }

  asBytes() {
    return this.bytes;
  }

  asMutBytes() {
    return this.bytes;
  }
}

export class Edict {
  // Constructor for Edict
  constructor(id, amount, output) {
    this.id = id;
    this.amount = amount;
    this.output = output;
  }
}

function encodeToTuple(n) {
  const tupleRepresentation = [];

  tupleRepresentation.push(Number(n & BigInt(0b0111_1111)));

  while (n > BigInt(0b0111_1111)) {
    n = n / BigInt(128) - BigInt(1);
    tupleRepresentation.unshift(
      Number((n & BigInt(0b0111_1111)) | BigInt(0b1000_0000))
    );
  }

  return tupleRepresentation;
}

// Encode a u128 value to a byte array
function varIntEncode(n) {
  const out = new Array(19).fill(0);
  let i = 18;

  out[i] = Number(BigInt(n) & 0b01111111n);

  while (BigInt(n) > 0b01111111n) {
    n = BigInt(n) / 128n - 1n;
    i -= 1;
    out[i] = Number(BigInt(n) | 0b10000000n);
  }

  return out.slice(i);
}

class Tag {
  static Body = 0;
  static Flags = 2;
  static Dune = 4;
  static Limit = 6;
  static Term = 8;
  static Deadline = 10;
  static DefaultOutput = 12;
  static Burn = 254;

  static Divisibility = 1;
  static Spacers = 3;
  static Symbol = 5;
  static Nop = 255;

  static take(tag, fields) {
    return fields.get(tag);
  }

  static encode(tag, value, payload) {
    payload.push(varIntEncode(tag));
    if (tag == Tag.Dune) payload.push(encodeToTuple(value));
    else payload.push(varIntEncode(value));
  }
}

class Flag {
  static Etch = 0;
  static Mint = 1;
  static Burn = 127;

  static mask(flag) {
    return BigInt(1) << BigInt(flag);
  }

  static take(flag, flags) {
    const mask = Flag.mask(flag);
    const set = (flags & mask) !== 0n;
    flags &= ~mask;
    return set;
  }

  static set(flag, flags) {
    flags |= Flag.mask(flag);
  }
}

const createScriptWithProtocolMsg = () => {
  // create an OP_RETURN script with the protocol message
  return new dogecore.Script().add("OP_RETURN").add(Buffer.from(IDENTIFIER));
};

// Construct the OP_RETURN dune script with encoding of given values
export function constructScript(
  etching = null,
  defaultOutput = undefined,
  burn = null,
  edicts = []
) {
  const payload = [];

  if (etching) {
    // Setting flags for etching and minting
    const flags = etching.mint
      ? Number(Flag.mask(Flag.Etch)) | Number(Flag.mask(Flag.Mint))
      : Number(Flag.mask(Flag.Etch));
    Tag.encode(Tag.Flags, flags, payload);
    if (etching.dune) Tag.encode(Tag.Dune, etching.dune, payload);
    if (etching.mint) {
      // don't include deadline right now
      //if (etching.mint.deadline) Tag.encode(Tag.Deadline, etching.mint.deadline, payload);
      if (etching.mint.limit)
        Tag.encode(Tag.Limit, etching.mint.limit, payload);
      if (etching.mint.term) Tag.encode(Tag.Term, etching.mint.term, payload);
    }
    if (etching.divisibility !== 0)
      Tag.encode(Tag.Divisibility, etching.divisibility, payload);
    if (etching.spacers !== 0)
      Tag.encode(Tag.Spacers, etching.spacers, payload);
    if (etching.symbol) Tag.encode(Tag.Symbol, etching.symbol, payload);
  }

  if (defaultOutput !== undefined) {
    Tag.encode(Tag.DefaultOutput, defaultOutput, payload);
  }

  if (burn) {
    Tag.encode(Tag.Burn, 0, payload);
  }

  if (edicts.length > 0) {
    payload.push(varIntEncode(Tag.Body));

    const sortedEdicts = edicts.slice().sort((a, b) => {
      const idA = BigInt(a.id);
      const idB = BigInt(b.id);

      return idA < idB ? -1 : idA > idB ? 1 : 0;
    });
    let id = 0;

    for (const edict of sortedEdicts) {
      if (typeof edict.id === "bigint")
        payload.push(varIntEncode(edict.id - BigInt(id)));
      else payload.push(varIntEncode(edict.id - id));
      payload.push(varIntEncode(edict.amount));
      payload.push(varIntEncode(edict.output));
      id = edict.id;
    }
  }

  // Create script with protocol message
  let script = createScriptWithProtocolMsg();

  // Flatten the nested arrays in the tuple representation
  const flattenedTuple = payload.flat();

  // Push payload bytes to script
  for (let i = 0; i < flattenedTuple.length; i += MAX_SCRIPT_ELEMENT_SIZE) {
    const chunk = flattenedTuple.slice(i, i + MAX_SCRIPT_ELEMENT_SIZE);
    const push = PushBytes.fromSliceUnchecked(chunk);
    script.add(Buffer.from(push.asBytes()));
  }

  return script;
}
